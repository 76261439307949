<template>
  <w-app>
    <w-drawer
      v-model="showDrawer"
      absolute
      width="200px"
      z-index="100"
      color="white"
      bg-color="sidebarBlue"
    >
      <div id="side-menu" class="ml5 mt6">
        <div class="mb4">
          <ul id="drawer-links" class="underlist">
            <li><a href="https://about.lafayette.edu/">About Us</a></li>
            <li><a href="https://academics.lafayette.edu/">Academics</a></li>
            <li><a href="https://campuslife.lafayette.edu/">Campus Life</a></li>
            <li>
              <a href="https://admissions.lafayette.edu/"
                >Admissions &amp; Aid</a
              >
            </li>
          </ul>
        </div>
        <div id="inset-links" class="mb4">
          <ul id="drawer-links2" class="ml3">
            <li>
              <w-icon class="mr1" md color="white">fa fa-map-signs</w-icon
              ><a href="https://www.lafayette.edu/visit/maps-directions/"
                >Directions</a
              >
            </li>
            <li>
              <w-icon class="mr1" md color="white">fa fa-bus</w-icon
              ><a href="http://www.lafayetteshuttle.org/"
                >The College Shuttle</a
              >
            </li>
            <li>
              <w-icon class="mr1" md color="white"
                >fa fa-exclamation-triangle</w-icon
              ><a href="https://directory.lafayette.edu/emergency"
                >Emergency Contact</a
              >
            </li>
            <li>
              <w-icon class="mr1" md color="white">fa fa-th-list</w-icon
              ><a href="https://directory.lafayette.edu/">Directory</a>
            </li>
            <li>
              <w-icon class="mr1" md color="white">fa fa-book</w-icon
              ><a href="https://libcat.lafayette.edu/screens/opacmenu.html"
                >Library Catlog</a
              >
            </li>
            <li>
              <w-icon class="mr1" md color="white">fa fa-gift</w-icon>
              <a
                href="https://securelb.imodules.com/s/1699/interior.aspx?sid=1699&gid=2&pgid=446&cid=1122"
                >Make a Gift</a
              >
            </li>
          </ul>
        </div>
        <div id="search" class="mb3">
          <form ref="searchform" method="GET" v-bind:action="url">
            <input type="hidden" name="client" value="Lafayette" />
            <input type="hidden" name="proxystylesheet" value="Lafayette" />
            <input type="hidden" name="output" value="xml_no_dtd" />
            <input type="hidden" name="site" value="Lafayette" />
            <input type="hidden" name="type" value="directory" />
            <w-input
              class="mb1"
              label="Search Lafayette"
              round
              outline
              :name="searchField"
              bg-color="white"
            >
            </w-input>
            <w-radios
              v-model="searchTargetSelection"
              :items="searchTargetChoices"
              color="white"
            >
            </w-radios>
          </form>
        </div>
        <div id="pulse" class="mb4">
          <h4>Our Pulse</h4>
          <ul id="pulse-list" class="underlist">
            <li><a href="http://www.goleopards.com/">Athletics</a></li>
            <li><a href="https://the-arts.lafayette.edu/">The Arts</a></li>
            <li><a href="https://news.lafayette.edu/">News</a></li>
            <li><a href="https://calendar.lafayette.edu/">Events</a></li>
            <li><a href="https://development.lafayette.edu/">Giving</a></li>
          </ul>
        </div>
        <div id="people" class="mb4">
          <h4>Our People</h4>
          <ul id="people-list" class="underlist">
            <li><a href="https://my.lafayette.edu/">Students</a></li>
            <li><a href="https://my.lafayette.edu/">Faculty &amp; Staff</a></li>
            <li><a href="https://families.lafayette.edu/">Families</a></li>
            <li><a href="https://alumni.lafayette.edu/">Alumni</a></li>
          </ul>
        </div>
      </div>
    </w-drawer>
    <div>
      <w-toolbar class="py3 w-flex wrap" bg-color="maroon" color="white">
        <div class="title2 no-shrink">
          <img class="scaled-down" src="@/assets/icons.svg" />
        </div>
        <div class="spacer"></div>
        <span class="ml2"
          ><w-icon
            class="mr1"
            md
            color="white"
            @click="showDrawer = !showDrawer"
            >fa fa-bars
          </w-icon></span
        >
      </w-toolbar>
      <div id="center-block">
        <h1 class="my12 text-bold">What is my IP?</h1>
        <w-flex wrap>
          <div v-if="showNetInfo" class="grow py6 xs12 md6 lg4 xl2">
            <p class="left">
              Your public IP address is <b>{{ public_ip }}</b>
            </p>
            <p class="left" v-if="virtual_ip">
              Your virtual IP address is <b>{{ virtual_ip }}</b
              >.
            </p>
            <p class="left" v-if="network_role">
              Your network role is <b>{{ network_role }}</b
              >.
            </p>
            <p class="left" v-if="reverse_dns">
              Your hostname is <b>{{ reverse_dns }}</b>
            </p>
            <p class="left" v-else>
              Your IP address does not have reverse DNS.
            </p>
            <p class="left">{{ network }}</p>
          </div>
          <div v-else class="grow py6 xs12 md6 lg4 xl2">
            <p class="left">Loading network information ...</p>
          </div>
          <aside class="py6 xs12 md6 lg4 xl2 align-end">
            <nav>
              <div>
                <ul class="left listnav">
                  <li>
                    <header class="related laf-maroon">
                      Related Information
                    </header>
                    <ul id="related-links" class="listnav underlist">
                      <li>
                        <a href="https://its.lafayette.edu/">ITS Home Page</a>
                      </li>
                      <li>
                        <a href="https://its.lafayette.edu/help-ticket/"
                          >Submit a Help Ticket</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://help.lafayette.edu/guidelines-for-strong-passwords/"
                          >Guidelines for Strong Passwords</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </aside>
        </w-flex>
      </div>
      <w-toolbar
        class="py6 mt10 footer w-flex wrap"
        bg-color="maroon"
        color="white"
      >
        <div class="no-shrink title2">
          <img class="scaled-down" src="@/assets/icons.svg" />
        </div>
        <div class="spacer"></div>
        <div id="footer-links">
          <span class="ml2">© 2023</span>
          <span class="ml2"
            ><a href="https://www.lafayette.edu/terms-of-use/"
              >Terms of Use</a
            ></span
          >
          <span class="ml2"
            ><a href="https://www.lafayette.edu/privacy/"
              >Privacy Policy</a
            ></span
          >
          <span class="ml2"
            ><a href="https://www.lafayette.edu/non-discrimination/"
              >Non-Discrimination Policy</a
            ></span
          >
        </div>
      </w-toolbar>
    </div>
  </w-app>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import "font-awesome/css/font-awesome.min.css";
import { get_netinfo } from "@/compositions/netapi";

export default {
  name: "App",
  setup() {
    const showNetInfo = ref(false);
    const public_ip = ref(null);
    const virtual_ip = ref(null);
    const network_role = ref(null);
    const reverse_dns = ref(null);
    const network = ref(null);
    const showDrawer = ref(false);
    const searchTargetChoices = ref(null);
    const searchTargetSelection = ref("site");
    const searchForm = ref(null);

    async function init() {
      document.title = "What is my IP?";
      searchTargetChoices.value = [
        { label: "Search the Site", value: "site" },
        { label: "Search the Directory", value: "directory" },
      ];
      await displayNetInfo();
    }

    async function displayNetInfo() {
      let netinfo = await get_netinfo();
      console.log(netinfo);
      let ip_addr = netinfo["ip_addr"];
      public_ip.value = ip_addr;
      let is_campus_addr = netinfo["is_campus_addr"];
      if (is_campus_addr) {
        network.value = "You are on the Lafayette College network.";
      } else {
        network.value = "You are not on the Lafayette College network.";
      }
      let virtual_addr = netinfo["virtual_ip"];
      virtual_ip.value = virtual_addr;
      if (virtual_addr) {
        network.value = "You are in the VPN virtual network.";
      }
      let hostname = netinfo["hostname"];
      if (hostname) {
        reverse_dns.value = hostname;
      }
      network_role.value = netinfo["role"];
      showNetInfo.value = true;
    }

    const url = computed(() => {
      if (searchTargetSelection.value == "directory") {
        return "https://directory.lafayette.edu/search/people";
      }
      return "https://search.lafayette.edu/search";
    });

    const searchField = computed(() => {
      if (searchTargetSelection.value == "directory") {
        return "query";
      }
      return "q";
    });

    onMounted(init);

    return {
      network,
      network_role,
      public_ip,
      reverse_dns,
      searchField,
      searchForm,
      searchTargetChoices,
      searchTargetSelection,
      showDrawer,
      showNetInfo,
      url,
      virtual_ip,
    };
  },
};
</script>

<style>
@import url('https://cloud.typography.com/7014354/640968/css/fonts.css');

#app {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}

img.scaled-down {
  width: 50%;
}

h1 {
  font-size: 3.75rem;
  text-align: left;
  text-rendering: optimizeLegibility;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
}

header {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  text-rendering: optimizeLegibility;
}
header.related {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}

div.border {
  border: 1px solid black;
}

.left {
  text-align: left;
  padding: 7px;
}

.right {
  text-align: right;
}

ul.listnav {
  list-style: none;
}

ul.underlist li {
  border-bottom: 1px solid silver;
  padding-top: 3px;
  padding-bottom: 5px;
}

.laf-maroon {
  color: #910029;
}

ul#related-links a {
  color: black;
}
ul#related-links a:hover {
  color: blue;
}

.footer a {
  color: white;
}
.footer a:hover {
  color: blue;
}

div#center-block {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

div#side-menu {
  color: "white";
  font-size: 1rem;
  font-weight: 250;
}
div#side-menu ul {
  list-style: none;
  text-align: left;
}
div#side-menu ul.underlist li {
  border-bottom: 1px solid white;
}

div#inset-links {
  background-color: #1d5f83;
  font-size: 1rem;
}
div#inset-links li {
  border-bottom: 1px solid #4ea8d8;
}
ul#drawer-links {
  font-size: 1.375rem;
}

div#side-menu h4 {
  color: black;
  text-align: left;
}
div#side-menu a {
  color: white;
}
div#side-menu a:hover {
  color: black;
}

div#search input {
  text-align: left;
}

div#search {
  color: black;
}
</style>
