import { createApp } from "vue";
import App from "./App.vue";
import WaveUI from "wave-ui";
import "wave-ui/dist/wave-ui.css";

const app = createApp(App);

new WaveUI(app, {
  // Some Wave UI options.
  colors: {
    maroon: "#910029",
    sidebarBlue: "#4EA8D8",
    sidebarDarkBlue: "#1D5F83",
  },
});

app.mount("#app");
