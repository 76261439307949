async function get_netinfo() {
  console.log(process.env.VUE_APP_NETWORK_API);
  const url = new URL(process.env.VUE_APP_NETWORK_API);
  let resp = null;
  try {
    resp = await fetch(url);
  } catch (ex) {
    console.log("Error during API call: " + ex);
    if (!resp.ok) {
      console.log("HTTP error during API call: " + resp.status);
      return;
    }
  }
  let result = null;
  try {
    result = await resp.json();
    result = JSON.parse(result["body"]);
  } catch (ex) {
    console.log("Could not decode JSON error message.");
    return null;
  }
  return result;
}

export { get_netinfo };
